interface GeneratePublishedUrlArg {
  shareId: string;
  path: 'share' | 'demo';
  baseUrl: string;
  search?: string;
}
export const generatePublishedUrl = ({
  shareId,
  path,
  baseUrl,
  search = '',
}: GeneratePublishedUrlArg): string => {
  return `${baseUrl}/${path}/${shareId}${search}`;
};
