import { CSSProperties, useEffect, useRef, useState } from 'react';
import screenfull from 'screenfull';

import { UseMeasureRef, useMeasure } from '../../hooks/react-use/useMeasure';
import {
  getRendererImageFrameDimensions,
  RendererImageFrameBottomBorder,
  RendererImageFrameTopBorder,
} from '../renderer/RendererImageFrame/RendererImageFrame';
import { DemoPlayerSmScreemBottomSpace } from '../../features/ui/ui.constants';
import { DisplayConfigType } from '../../features/project/project.types';
import getFinalRendererDimensions from '../../utils/getFinalRendererDimensions';

interface UseEmbedProjectContainerResizeProps {
  data: {
    display_config: DisplayConfigType;
    dimensions?: {
      width: number;
      height: number;
    };
    isResponsive: boolean;
  };
}

/**
 * In Preview & Share pages we need to fit demo-player into container(avoid overflow scrollbars).
 *
 * Since player is based on width and uses `padding-bottom` approach to persist aspect ratio,
 * we need to calculate optimal `width` of a container(place where player will be placed into).
 *
 * Typical aspect ratio of an image-project is going to be 1320x800 (1.65 aspect ratio).
 *
 * Example:
 *
 * 1) Container size is 1000:500.
 * [x] Player height based on container's width is: 1000px / 1.65 ≈ 606px > 500px - will cause vertical overflow
 * [✓] Player width based on container's height is: 500px * 1.65 = 852px < 1000px - will fit into container
 *
 * 2) Container size is 700:600.
 * [✓] Player height based on container's width is: 700px / 1.65 ≈ 424px < 600px - will fit into container
 * [x] Player width based on container's height is: 600px * 1.65 = 990px - will  cause horizontal container
 */
function useEmbedProjectContainerResize<T extends Element = Element>({
  data,
}: UseEmbedProjectContainerResizeProps): [
  /**
   * `ref` — container where embed project is placed.
   * Use element's dimensions as maximum available height\width.
   */
  ref: UseMeasureRef<T>,
  style: CSSProperties
] {
  const [ref, refDimensions] = useMeasure();
  const [isMeasureEnabled, setIsMeasureEnabled] = useState(true);
  const elementDimensions = useRef(refDimensions);
  if (isMeasureEnabled) {
    elementDimensions.current = refDimensions;
  }
  useEffect(() => {
    if (screenfull.isEnabled) {
      const errorHandler = (event: Event) => {
        alert(`Failed to enable fullscreen, reason: ${event}`);
      };

      const changeHandler = () => {
        // exiting fullscreen
        if (!screenfull.isFullscreen) {
          return setTimeout(
            () => setIsMeasureEnabled(screenfull.isFullscreen),
            300
          );
        }

        // entering fullscreen
        setIsMeasureEnabled(false);
      };

      screenfull.on('error', errorHandler);
      screenfull.on('change', changeHandler);

      return () => {
        screenfull.off('error', errorHandler);
        screenfull.off('change', changeHandler);
      };
    }
  }, []);

  const rendererFrameDimensions = getRendererImageFrameDimensions(
    data.display_config.renderer_border_color
  );

  const dimensions = getFinalRendererDimensions(
    data.display_config,
    data.dimensions
  );

  const demoAspectRatio = dimensions.width / dimensions.height;

  const rendererHeight =
    elementDimensions.current.height -
    (data.isResponsive
      ? DemoPlayerSmScreemBottomSpace +
        RendererImageFrameTopBorder +
        RendererImageFrameBottomBorder
      : rendererFrameDimensions.top + rendererFrameDimensions.bottom);

  const rendererWidth = demoAspectRatio * rendererHeight;

  const containerWidth = Math.floor(
    Math.min(
      rendererWidth,
      dimensions.width +
        rendererFrameDimensions.left +
        rendererFrameDimensions.right
    )
  );

  const style = { width: `min(${containerWidth}px, 100%)` };

  return [ref, style];
}

export default useEmbedProjectContainerResize;
