import { FC, ReactNode } from 'react';

import styles from './ShareImagePortraitLayout.module.css';
import SharePageImageHeader from 'components/SharePageImageHeader';

interface ShareImagePortraitLayoutProps {
  title: string;
  hrefUrl: string;
  linkElement: ReactNode;
  logoSrc: string | null;
  disabledAuthenticatedTooltip: boolean;
}
const ShareImagePortraitLayout: FC<ShareImagePortraitLayoutProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <SharePageImageHeader {...otherProps} />
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default ShareImagePortraitLayout;
