import { useEffect, useState } from 'react';

export function useListenCrossOriginEvents({
  eventAction,
}: {
  eventAction: string;
}) {
  const [eventData, setEventData] = useState<any>(null);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event?.data?.payload?.event === eventAction) {
        setEventData(event.data.payload);
      }
    };

    window?.addEventListener('message', handleMessage);

    return () => window?.removeEventListener('message', handleMessage);
  }, [eventAction]);

  return { eventData };
}
