import { Routes } from 'shared-components/src/features/router/router.constants';
import { useCallback, useState } from 'react';
import { ProjectId } from 'shared-components/src/features/project/project.types';

import PresenterNotesPopup from './PresenterNotesPopup';
import PresenterNotesToggler from './PresenterNotesToggler';

interface PresenterNotesOpenerProps {
  projectId: ProjectId;
  currentPageId: string;
}

const PresenterNotesOpener = ({
  projectId,
  currentPageId,
}: PresenterNotesOpenerProps): JSX.Element => {
  const [isPresenterNotesOpen, setIsPresenterNotesOpen] =
    useState<boolean>(true);

  const handleVisibilityToggler = useCallback(() => {
    setIsPresenterNotesOpen((prev) => !prev);
  }, [setIsPresenterNotesOpen]);

  const handleOpenNotes = () => {
    const newWindow = window.open(
      `${process.env.NEXT_PUBLIC_DASHBOARD_URL}${Routes.PresenterNotes.replace(
        ':projectId',
        projectId
      )}?page_id=${currentPageId}`,
      'newwindow',
      'width=900, height=650'
    );

    if (newWindow) {
      newWindow.focus();
    }

    setIsPresenterNotesOpen(false); // Update the visibility state
  };

  if (!isPresenterNotesOpen) {
    return <PresenterNotesToggler onClick={handleVisibilityToggler} />;
  }

  return (
    <PresenterNotesPopup
      handleOpenNotes={handleOpenNotes}
      handleCloseNotes={handleVisibilityToggler}
    />
  );
};

export default PresenterNotesOpener;
