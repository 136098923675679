import { VFC } from 'react';
import EmbedProjectHtml from 'shared-components/src/components/EmbedProjectHtml';
import { generatePublishedUrl } from 'shared-components/src/utils/generatePublishedUrl';
import { useRouter } from 'next/router';
import Head from 'next/head';

import styles from './SharePageHtml.module.css';
import { getSearchParams } from 'utils/getSearchParams';
import { isHtmlScaleEnabled } from 'utils/isHtmlScaleEnabled';
import PresenterNotesOpener from 'components/PresenterNotesOpener';
import { CrossOriginSessionEventEvent } from 'container/PublicDemoPage/PublicDemoPage.constants';
import { useListenCrossOriginEvents } from './SharePageHtml.hooks';
import { useIsLoggedIn } from 'utils/isLoggedIn';
import { GetPublishedProjectInfoResponse } from '../api/projectInfo.api';
import {
  ChromeEventActionTypes,
  useListenForChromeExtensionMessages,
} from 'hooks/useListenForChromeExtensionMessages';

interface SharePageHtmlProps {
  projectId: string;
  projectInfo: GetPublishedProjectInfoResponse['project'];
}

const SharePageHtml: VFC<SharePageHtmlProps> = ({ projectId, projectInfo }) => {
  /**
   * Read details about /share page query params.
   * @see {@link https://storylane.atlassian.net/l/cp/EgYr1JNg Docs}
   */
  const router = useRouter();

  const { eventData: pageViewedCrossEventResponse } =
    useListenCrossOriginEvents({
      eventAction: CrossOriginSessionEventEvent.PageChanged,
    });

  const currentPageId = pageViewedCrossEventResponse?.page?.id;

  const search = getSearchParams(router.asPath);
  const nextSearch = new URLSearchParams(search.toString());

  const isEmbed = search.get('embed'); // `embed` flag is for debug purposes to render <iframe /> inside a small div.

  const { eventData: chromeUserEventData } =
    useListenForChromeExtensionMessages<{
      company_id: string;
    }>({
      eventAction: ChromeEventActionTypes.StorylaneShareUserId,
    });

  const extensionCompanyId = chromeUserEventData?.company_id;

  const isLoggedIn = useIsLoggedIn();

  const isPresenterNotesVisible =
    projectInfo?.presenter_notes_available &&
    currentPageId &&
    isLoggedIn &&
    extensionCompanyId === projectInfo.company_id;

  // `scale` flag to render <iframe /> inside a container smaller than "renderer dimensions".
  const isScale = isHtmlScaleEnabled({
    displayConfigRendererDimensions:
      projectInfo.display_config.renderer_dimensions,
    startPageDimensions: projectInfo.dimensions,
    queryFlagScale: search.get('scale') === 'true',
    featureFlagScale: projectInfo.feature_flags.auto_scale,
  });

  if (isEmbed || isScale) {
    // remove "/share" specific flags from  "/demo" search.
    nextSearch.delete('embed');

    const url = generatePublishedUrl({
      shareId: projectId,
      path: 'demo',
      baseUrl: process.env.NEXT_PUBLIC_HOST_URL || '',
      search: `?${nextSearch.toString()}`,
    });

    return (
      <div className={styles.root}>
        <div className={styles.embedDemoContainer}>
          <Head>
            <link rel="preload" as="document" href={url} />
          </Head>
          <EmbedProjectHtml url={url} testId="share-embed-demo" isBorderless />
          {isPresenterNotesVisible && (
            <PresenterNotesOpener
              currentPageId={currentPageId}
              projectId={projectInfo.id}
            />
          )}
        </div>
      </div>
    );
  }

  const url = generatePublishedUrl({
    shareId: projectId,
    path: 'demo',
    baseUrl: process.env.NEXT_PUBLIC_HOST_URL || '',
    search: `?${nextSearch.toString()}`,
  });

  return (
    <>
      <Head>
        <link rel="preload" as="document" href={url} />
      </Head>
      <EmbedProjectHtml testId="share-html-embed-demo" url={url} isBorderless />

      {isPresenterNotesVisible && (
        <PresenterNotesOpener
          currentPageId={currentPageId}
          projectId={projectInfo.id}
        />
      )}
    </>
  );
};

export default SharePageHtml;
