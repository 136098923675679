import { DisplayConfigType } from './project.types';

/**
 * Process "display config" value coming from BE
 *
 * @param displayConfig raw display config from BE
 * @return post processed display config
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/458807/Display+Config Docs}
 */
export const processDisplayConfig = (
  displayConfig: DisplayConfigType,
  overrides: Partial<{ auto_play: number }> = {}
): DisplayConfigType => {
  const isMobileViewport = displayConfig.mobile_viewport;
  const minWidth = displayConfig.min_width ?? 0;

  return {
    ...displayConfig,
    min_width: isMobileViewport ? 0 : minWidth,
    mobile_viewport: isMobileViewport,
    min_width_warning:
      !isMobileViewport &&
      Boolean(displayConfig.min_width) &&
      displayConfig.min_width_warning,
    auto_play: overrides.auto_play ?? displayConfig.auto_play,
  };
};
