export function updateQueryStringParameter(key: string, value: string) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);
  const newParams = queryParams.toString();

  window.history.pushState(
    {
      [key]: value,
    },
    '',
    window.location.pathname + '?' + newParams
  );
}
