export enum Routes {
  Login = '/login',
  LoginCallback = '/login/callback',

  AutoLogin = '/auto-login',
  AutoLoginUserByToken = '/auto-login/:userId/:token',

  OauthCallback = '/oauth/callback',

  SignUp = '/sign-up',

  Join = '/join/:token',

  InstallExtension = '/install-extension',

  PasswordRestore = '/restore-password',
  PasswordChange = '/change-password/:code',

  SetCompanyInfo = '/set-company-info',

  SetTeamName = '/set-team-name',

  VerifyEmail = '/verify-email',

  ProjectEditor = '/project/:id',
  ProjectEditorWithPage = '/project/:id/:pageId',

  Projects = '/projects',
  ProjectDetails = '/projects/:id',

  Billing = '/billing',

  Settings = '/settings',

  Preview = '/preview/:id',

  Analytics = '/analytics',
  AnalyticsActivity = '/analytics/activity',
  AnalyticsActivityDetails = '/analytics/activity/:id',
  AnalyticsInsights = '/analytics/insights',
  Accounts = '/analytics/accounts',
  CompanyAccounts = '/analytics/accounts/:id',

  TeamMembers = '/team/members',
  TeamSettings = '/team/settings',

  Integrations = '/integrations',

  SetCompany = '/set-company/:companyId',

  DemoHubEditor = '/hubs/editor/:demoHubId',
  DemoHub = '/hubs',
  DemoHubPreview = '/hub/preview/:demoHubId',
  DemoHubShare = '/hub/:shareId',

  DemoHubDetails = '/hubs/details/:id',
  DemoHubSessionAnalytics = '/hubs/:hub_id/analytics/:session_id',
  PresenterNotes = '/project/:projectId/presenter',
}
