import Icon from 'shared-components/src/components/Icon';

import styles from './PresenterNotesToggler.module.css';

interface PresenterNotesTogglerProps {
  onClick: VoidFunction;
}

const PresenterNotesToggler = ({ onClick }: PresenterNotesTogglerProps) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Icon name="notes-filled" width={16} height={16} />
    </div>
  );
};

export default PresenterNotesToggler;
