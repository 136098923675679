import {
  ChromeEventActionTypes,
  useListenForChromeExtensionMessages,
} from 'hooks/useListenForChromeExtensionMessages';

export const useIsLoggedIn = () => {
  const { eventData: chromeUserEventData } =
    useListenForChromeExtensionMessages<{ user_id: string }>({
      eventAction: ChromeEventActionTypes.StorylaneShareUserId,
    });

  return Boolean(chromeUserEventData?.user_id);
};
