import { GetServerSideProps, NextPage } from 'next';

import SharePage from 'container/SharePage';
import {
  GetPublishedProjectInfoResponse,
  getSharedProjectInfo,
} from 'container/SharePage/api/projectInfo.api';
import { isValidId } from 'utils/isValidId';
import { getIpAddress } from 'utils/getUserIpAddress';

const Share: NextPage<GetPublishedProjectInfoResponse> = (props) => (
  <SharePage project={props.project} />
);

export const getServerSideProps: GetServerSideProps = async (context) => {
  const shareId = Array.isArray(context.query.id)
    ? context.query.id[0]
    : context.query.id ?? '';

  const userAgent = context.req
    ? context.req.headers['user-agent']
    : navigator.userAgent;

  const userIP = getIpAddress(context.req);

  if (!isValidId(shareId)) {
    return {
      notFound: true,
      props: {
        userAgent,
        project: null,
      },
    };
  }

  const additionalQuery = Array.isArray(context.query.additionalQuery)
    ? context.query.additionalQuery[0]
    : context.query.additionalQuery ?? '';

  try {
    const response = await getSharedProjectInfo({
      shareId: shareId,
      additionalQuery,
      userIP,
      userAgent,
    });
    return {
      props: {
        project: response.project,
        meta: {
          favicon: response.project?.whitelabel?.favicon,
          userAgent,
        },
      },
    };
  } catch (error) {
    return {
      props: {
        project: null,
      },
    };
  }
};

export default Share;
