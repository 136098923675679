import { useEffect } from 'react';

function useSharePageTopWindowConsoleError() {
  useEffect(() => {
    const sharePageWindowIsNotTopWindow = window.top !== window.self;
    if (sharePageWindowIsNotTopWindow) {
      const errorMessage = `Avoid using ${window.location.href} inside the iframe. Correct demo url can be copied from the editor page. Visit: ${process.env.NEXT_PUBLIC_DASHBOARD_URL}`;
      console.error(errorMessage);
    }
  }, []);
}

export { useSharePageTopWindowConsoleError };
