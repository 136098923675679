import { FC, ReactNode } from 'react';

import SharePageImageHeader from 'components/SharePageImageHeader';
import styles from './ShareImageLandscapeLayout.module.css';

interface ShareImageLandscapeLayoutProps {
  title: string;
  hrefUrl: string;
  linkElement: ReactNode;
  showFooterLinkElement: boolean;
  logoSrc: string | null;
  disabledAuthenticatedTooltip: boolean;
}
const ShareImageLandscapeLayout: FC<ShareImageLandscapeLayoutProps> = (
  props
) => {
  const { children, linkElement, showFooterLinkElement, ...otherProps } = props;
  return (
    <div className={styles.root}>
      <SharePageImageHeader {...otherProps} linkElement={linkElement} />
      <div className={styles.body}>{children}</div>
      {showFooterLinkElement && (
        <div className={styles.footer}>{linkElement}</div>
      )}
    </div>
  );
};

export default ShareImageLandscapeLayout;
