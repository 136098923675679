import { VFC, ReactNode } from 'react';

import ShareStorylaneDefaultLogo from 'components/ShareStorylaneDefaultLogo';
import styles from './SharePageImageHeader.module.css';

interface SharePageImageHeaderProps {
  title: string;
  hrefUrl: string;
  logoSrc: string | null;
  disabledAuthenticatedTooltip: boolean;
  linkElement: ReactNode;
}

const SharePageImageHeader: VFC<SharePageImageHeaderProps> = (props) => {
  const { linkElement, title, hrefUrl, logoSrc, disabledAuthenticatedTooltip } =
    props;
  return (
    <header className={styles.root}>
      <a
        className={styles.logoContainer}
        href={hrefUrl}
        data-testid="white-label-href"
      >
        {logoSrc ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={logoSrc}
            className={styles.logo}
            alt={title || 'Storylane demo'}
            data-testid="white-label-logo"
          />
        ) : (
          <ShareStorylaneDefaultLogo
            disabledAuthenticatedTooltip={disabledAuthenticatedTooltip}
          />
        )}
      </a>
      <h1 key={`header-${title}`} className={styles.title}>
        <span>{title}</span>
      </h1>
      <span key="header-link" className={styles.link}>
        {linkElement}
      </span>
    </header>
  );
};

export default SharePageImageHeader;
